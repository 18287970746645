<script lang="ts">
  import { getLoginUrl } from '@amedia/user';

  import ErrorPage from '$components/common/error-page/error-page.svelte';
  import Incentive from '$components/common/incentive/incentive.svelte';
  import Nav from '$components/common/nav/nav.svelte';
  import Onboarding from '$components/common/onboarding/onboarding.svelte';
  import PersonalizedWarning from '$components/common/personalized-warning/personalized-warning.svelte';
  import ExploreWidget from '$components/common/widgets/explore-widget.svelte';
  import SavedWidget from '$components/common/widgets/saved-widget.svelte';
  import SubscriptionServices from '$components/common/widgets/subscriptions-widget.svelte';
  import ConfigContext from '$components/context/config.context.svelte';
  import UserContext from '$components/context/user.context.svelte';
  import SingleColumn from '$components/layout/single-column.svelte';
  import ThreeColumn from '$components/layout/three-column.svelte';
  import BookmarksRoute from '$components/routes/bookmarks.svelte';
  import FrontpageRoute from '$components/routes/frontpage.svelte';
  import ExploreRoute from '$components/routes/explore.svelte';
  import SettingsRoute from '$components/routes/settings.svelte';
  import SiteProfile from '$components/routes/site-profile.svelte';
  import SitesList from '$components/routes/sites-list.svelte';
  import Loading from '$components/style/loading/loading.svelte';
  import Spacer from '$components/style/spacer/spacer.svelte';
  import { Locations, Router } from '$stores/router-store.js';
  import { user } from '$stores/user-store.js';
  import { UserState } from '$stores/user-store';

  export let parameters: { emergencyMode: string };

  const emergencyMode = parameters.emergencyMode;

  const routes = {
    [Locations.Home]: FrontpageRoute,
    [Locations.Explore]: ExploreRoute,
    [Locations.Sports]: ExploreRoute,
    [Locations.Readinglist]: BookmarksRoute,
    [Locations.Settings]: SettingsRoute,
    [Locations.Publications]: SitesList,
    [Locations.SiteProfile]: SiteProfile,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [Locations.Article]: null as any, // We need to type this as any else Typescript will complain about the missing $$props type in the markup below
  };

  const pathNames = {
    [Locations.Home]: 'Hjem',
    [Locations.Explore]: 'Oppdag',
    [Locations.Sports]: 'Sport',
    [Locations.Info]: 'Info',
    [Locations.Readinglist]: 'Lagret',
    [Locations.Settings]: 'Profil',
    [Locations.Publications]: 'Aviser',
    [Locations.Article]: 'Artikkel',
    [Locations.SiteProfile]: 'Profilside',
  };

  const loginUrl = getLoginUrl();

  let innerWidth: number;

  $: route = $Router.path;

  $: showPersonalizedWarning =
    $user.state >= UserState.HASACCESS &&
    $user.data?.personalizedContent === false;

  $: showWidgets =
    innerWidth >= 768 &&
    !showPersonalizedWarning &&
    $user.state === UserState.ONBOARDED &&
    route !== Locations.Info;

  $: layout =
    $user.state >= UserState.HASACCESS && innerWidth >= 768
      ? ThreeColumn
      : // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (SingleColumn as any);
</script>

<svelte:head>
  <title>ALT: {pathNames[$Router.path]}</title>
</svelte:head>

<svelte:window bind:innerWidth />

<ConfigContext
  let:primarySiteInfo
  let:sitesDomains
  let:sitesNames
  let:domainKeys
  let:publicationsList
>
  {@const { motherLink, motherLinkLabel, motherLogo, primarySite } =
    primarySiteInfo}

  {#key $Router.path}
    <!-- Meta Element used for logging-->
    <div
      itemscope
      itemtype="https://www.adplogger.no/json-schema/CustomElement"
    >
      <meta itemprop="custom-element#name" content={$Router.path} />
      <!-- Layout -->
      <svelte:component
        this={layout}
        mainColumnSpan={$user.state === UserState.ONBOARDED &&
        route !== Locations.Info
          ? 1
          : 2}
        firstColumnWidth={$user.state === UserState.HASACCESS ? '5fr' : '2fr'}
        dividers={$user.state === UserState.ONBOARDED}
      >
        <!-- Header and Navigation -->
        <svelte:fragment slot="nav">
          <Nav
            position={innerWidth >= 768 && $user.state >= UserState.HASACCESS
              ? 'left'
              : 'top'}
            logoType={$user.state < UserState.LOGGEDIN ||
            showPersonalizedWarning
              ? 'logo-alt-word'
              : 'logo-alt'}
            location={$Router.path}
            showTabbar={$user.state === UserState.ONBOARDED}
            showLinks={$user.state === UserState.ONBOARDED &&
              !showPersonalizedWarning}
            showInfoPageLinks={$user.state < UserState.HASACCESS}
            showOnboardingHelper={$user.state === UserState.HASACCESS &&
              !$user.error}
            {motherLink}
            {motherLogo}
            {motherLinkLabel}
            on:change={(event) => Router.navigate(event.detail.location)}
          />
        </svelte:fragment>

        <svelte:fragment slot="main">
          <UserContext>
            <!-- Loading -->
            <Loading slot="loading" />

            <!-- Content -->
            <svelte:fragment slot="success" let:userData>
              <!-- If user has turned off personalization -->
              {#if showPersonalizedWarning}
                <PersonalizedWarning />

                <!-- If user is not onboarded yet -->
              {:else if $user.state === UserState.HASACCESS}
                <Onboarding
                  user={userData}
                  {primarySite}
                  isDesktop={innerWidth >= 768}
                />

                <!-- If user is visiting our info page -->
              {:else if route === Locations.Info}
                <Incentive
                  loggedIn={$user.state === UserState.LOGGEDIN}
                  {loginUrl}
                />

                <!-- If user is visiting our publicationslist -->
              {:else if route === Locations.Publications}
                <SitesList publicationsList={publicationsList.sites} />

                <!-- If user is visiting our Site Profiles -->
              {:else if route === Locations.SiteProfile}
                <SiteProfile publicationsList={publicationsList.sites} />

                <!-- If everything is OK! -->
              {:else if $user.state === UserState.ONBOARDED}
                <svelte:component
                  this={routes[route]}
                  {userData}
                  {primarySite}
                  {sitesDomains}
                  {domainKeys}
                  {sitesNames}
                  publicationsList={publicationsList.sites}
                  {innerWidth}
                  location={$Router.path}
                />

                <!-- If user is not logged in -->
              {:else}
                <Incentive
                  loggedIn={$user.state === UserState.LOGGEDIN}
                  {loginUrl}
                />
              {/if}
            </svelte:fragment>

            <!-- Error Handling -->
            <ErrorPage slot="error" {emergencyMode} />
          </UserContext>
        </svelte:fragment>

        <!-- Widgets -->
        <svelte:fragment slot="widgets">
          {#if showWidgets}
            <SubscriptionServices {motherLink} />
            <Spacer spacing="$x6" />
            <UserContext>
              <ExploreWidget
                slot="success"
                let:userData
                {userData}
                location={$Router.path}
              />
            </UserContext>
            <Spacer spacing="$x6" />
            {#if $Router.path !== Locations.Readinglist}
              <SavedWidget />
              <Spacer spacing="$x6" />
            {/if}
          {/if}
        </svelte:fragment>
      </svelte:component>
    </div>
  {/key}
</ConfigContext>

<style>
  :global(amedia-alt-page) {
    background: var(--color-baseBody, #f4efe9);
    display: block;
  }

  :global(main iframe) {
    max-width: 100% !important;
  }

  div[itemscope] {
    display: contents;
  }
</style>
