<script lang="ts">
  import { css } from '@amedia/brick-tokens';
  import { onMount } from 'svelte';

  import Pubselector from '$components/common/publication-plist/publication-plist.svelte';
  import Bruker from '$components/common/user-info/user-info.svelte';
  import Accordion from '$components/style/accordion/accordion.svelte';
  import Button from '$components/style/button/button.svelte';
  import Card from '$components/style/card/card.svelte';
  import Icon from '$components/style/icon/icon.svelte';
  import Spacer from '$components/style/spacer/spacer.svelte';
  import i18n from '$i18n/i18n.js';
  import { user } from '$stores/user-store';
  import {
    personalizedSitesList,
    type SitesDomains,
  } from '$stores/sites-store';
  import { customClickEvent } from '$utils/adplogger';
  import { WHITELIST_TESTUSERS } from '$utils/consts';
  import {
    deleteUserPreferences,
    savePublicationPreferences,
  } from '$utils/queries.js';

  export let primarySite: string;
  export let sitesDomains: SitesDomains;

  let sites: Awaited<ReturnType<typeof personalizedSitesList>> | never[] = [];

  const mainStyle = css({
    padding: '0 $x4 $x20',
    maxWidth: '980px',
    marginInline: 'auto',
  });

  const titleStyle = css({
    fontstyle: 'baseHeadlineL',
    marginBlock: '$x6',
  });

  const listStyle = css({
    listStyle: 'none',
    padding: 0,
    margin: 0,

    display: 'flex',
    flexDirection: 'column',
    gap: '$x4',
  });

  const deletePreferencesWrapper = css({
    width: '100%',
    textAlign: 'right',
    marginBottom: '$x6',
  });

  const feedbackTitleStyle = css({
    alignItems: 'center',
    fontstyle: 'baseHeadlineS',
    fontFeatureSettings: "'ss02'",
    margin: '0px',
    display: 'flex',
    gap: '$x2',
  });

  async function handleSelectedSites(e: CustomEvent) {
    const { siteKey, checked } = e.detail;

    const event = customClickEvent(
      'mypublications-update',
      JSON.stringify({
        siteKey,
        preferred: checked,
      })
    );

    e.target?.dispatchEvent(event);
    await savePublicationPreferences({
      siteKey,
      preferred: checked,
    });
  }

  const handleDeletePreferences = () => {
    const uuid = $user.data?.uuid ?? '';

    if (WHITELIST_TESTUSERS.includes(uuid)) {
      deleteUserPreferences(uuid)
        .then(() => {
          // @ts-expect-error - reload actually does take the parameter boolean (in FireFox). See https://developer.mozilla.org/en-US/docs/Web/API/Location/reload
          window.location.reload(true);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  $: selectedSites =
    $user.data?.preferredSites.map(({ siteKey }) => siteKey) || [];

  onMount(async () => {
    try {
      sites = await personalizedSitesList($user.data?.uuid ?? '');
    } catch (e) {
      console.error('Could not get sites', e);
    }
  });
</script>

<main class={mainStyle()} id="main-content-begin">
  <h1 class={titleStyle()}>{$i18n.t('settings.heading')}</h1>
  {#if WHITELIST_TESTUSERS.includes($user.data?.uuid || '')}
    <div class={deletePreferencesWrapper()}>
      <Button
        clickLabel="preferences"
        clickValue="delete-preferences"
        id="delete-preferences"
        type="signal"
        text="Slett preferanser"
        on:click={handleDeletePreferences}
      />
    </div>
  {/if}
  <ul class={listStyle()}>
    <li>
      <Accordion
        title={$user.data?.name ?? 'Din informasjon'}
        icon="profile-fill"
        clicklabel="myalt"
        open
      >
        <Bruker accessDomain={sitesDomains[primarySite]} />
      </Accordion>
    </li>
    <li>
      <Accordion
        title={$i18n.t('settings.publications.title')}
        icon="Paper-outline"
        clicklabel="mypublications"
        open
      >
        <Pubselector {sites} {selectedSites} on:update={handleSelectedSites} />
      </Accordion>
    </li>
    <li>
      <Card>
        <h2 class={feedbackTitleStyle()}>
          <Icon icon="logo-outline" size={22} />Hva synes du om ALT?
        </h2>
        <Spacer />
        <Button
          text="Gi oss din tilbakemelding"
          clickLabel="feedback"
          clickValue="add-feedback"
          type="signal"
          size="medium"
          id="add-feedback"
          icon="link-external"
          iconPosition="right"
          href="https://docs.google.com/forms/d/e/1FAIpQLScyNM55cJSl0DziFROyGTJY9A2WrfSGm7BN7zydhaX-C0xuNw/viewform"
        />
      </Card>
    </li>
  </ul>
</main>
